import React from 'react'
import styled from '@emotion/styled'

import useCompanyInfo from 'web-client/components/JobDetails/JobDetailsMap/useCompanyInfo'
import swoopLogo from '../images/swoop_logo.svg'

const LogoLink = styled.a`
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding: 0 28px;
`

const CustomLogo = styled.span`
  font-size: 0;
  height: 100%;
`

const CompanyLogoImg = styled.img`
  display: inline-block;
  height: 36px;
  margin: 0;
  max-height: 100%;
  vertical-align: middle;
  width: auto;
`

const SwoopHeaderLogo = styled.i`
  height: 20px;
  width: 171px;
  display: initial;
  background: url(${swoopLogo});
`

type CompanyLogoProps = {
  useSwoopLogo?: boolean
}

const CompanyLogo = ({ useSwoopLogo = false }: CompanyLogoProps) => {
  const companyInfo = useCompanyInfo()
  if (!companyInfo && !useSwoopLogo) {
    return null
  }

  return (
    <LogoLink href="/">
      {companyInfo?.logoUrl ? (
        <CustomLogo>
          <CompanyLogoImg alt="Company Logo" src={companyInfo.logoUrl} />
        </CustomLogo>
      ) : (
        <SwoopHeaderLogo />
      )}
    </LogoLink>
  )
}
export default CompanyLogo
